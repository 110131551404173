<template>
  <div class="period-display">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="tenderGuid"
            placeholder="标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="periodList"
      header-cell-class-name="bg-info text-light"
      border
      >
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column prop="periodName" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="periodAlias" header-align="center" align="center" label="别名"></el-table-column>
      <el-table-column prop="endDate" width="200" header-align="center" align="center" label="申报日期"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import examinationService from '@/services/examinationService'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'

export default {
  name: 'PeriodDisplay',
  mixins: [
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      tenderGuid: '',
      tenders: [],
      periodList: []
    }
  },
  watch: {
    tenderGuid (newVal) {
      newVal && this._getPeriodList()
    }
  },
  methods: {
    _getPeriodList () {
      examinationService.getAddedPeriods(this.tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.periodList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenders()
  }
}
</script>
